import { useEffect, useState } from "react";
import "./App.css";

import GalenAILogo from "./assets/GalenAI_Logo.png";
import GalenGIF from "./assets/Galen.gif";

function App() {
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      const tempWidth = window.innerWidth;
      setCurrentWidth(tempWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [currentWidth]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
      }}
      className="flex-column flex-justify-center flex-align-center"
    >
      <div
        style={{
          overflow: "auto",
          position: "absolute",
          top: "12px",
          left: "12px",
        }}
      >
        <img
          style={{
            height: "32px",
          }}
          src={GalenAILogo}
          alt="Galen Logo"
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: currentWidth > 500 ? "row" : "column-reverse",
          columnGap: "64px",
          maxWidth: "1000px",
          padding: "32px",
        }}
      >
        <div
          className="flex-column flex-equal"
          style={{
            width: currentWidth > 500 ? "50%" : "100%",
          }}
        >
          <h1 className="t-font">Meet Galen</h1>
          <div className="p-medium h-font txt-regular">
            Galen.AI is a medical intelligence model trained for advance medical
            reasoning, vision transcription, and medical document generation.
            Whatever assistance you need, Galen is here to help.
          </div>
          <br />
          <br />
          <div className="p-medium h-font">
            Releasing <b>November 2024...</b>
          </div>
        </div>
        <div className="flex-column flex-align-center flex-equal">
          <img
            style={{
              // aspectRatio: "1",
              width: currentWidth > 500 ? "90%" : "100%",
            }}
            src={GalenGIF}
            alt="Galen"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
